<script lang="ts" setup>
import type {
  ComponentBlockPartner,
  ComponentBlockPartnerFragment,
} from "~~/types/graphql";

import {
  Enum_Componentblockpartner_Variation,
} from "~~/types/graphql";

import Arrow from "@/assets/icons/arrow.svg?component";

const props = defineProps<{
  PartnerVariation: Enum_Componentblockpartner_Variation;
  PartnerLink: ComponentBlockPartnerFragment["PartnerLink"];
  PartnerHeadline: ComponentBlockPartnerFragment["PartnerHeadline"];
  PartnerPartnerLinks: ComponentBlockPartnerFragment["PartnerPartnerLinks"];
  PartnerText: ComponentBlockPartnerFragment["PartnerText"];
  PartnerSubheadline: ComponentBlockPartnerFragment["PartnerSubheadline"];
}>();
</script>
<template>
  <section class="partner">
    <layout-block
      v-if="props.PartnerHeadline || props.PartnerSubheadline || props.PartnerText"
      :title="props.PartnerSubheadline"
      :link="props.PartnerLink?.data?.attributes?.Slug"
      class="partner md:bg-transparent"
      :class="{
        'bg-light-gray':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Dark,
      }"
    >
      <div class="grid md:grid-cols-12 gap-5.4">
        <h2
          class="text-h2-s md:text-h2 font-pixel md:row-start-1 md:col-start-7 md:col-end-13 lg:col-start-8 lg:col-end-12 hyphens-auto"
          v-if="props.PartnerHeadline"
        >
          {{ props.PartnerHeadline }}
        </h2>
        <base-text
          v-if="props.PartnerText"
          :Text="props.PartnerText"
          class="md:row-start-1 md:col-start-1 md:col-end-7 lg:col-start-1 lg:col-end-6 md:col-span-6"
        />
      </div>
    </layout-block>
    <div
      :class="{
        'bg-integer-black':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Dark,
        'bg-integer-white':
          props.PartnerVariation === Enum_Componentblockpartner_Variation.Light,
      }"
    >
      <div class="partner-links md:flex justify-around container">
        <nuxt-link
          v-for="partnerLink in props.PartnerPartnerLinks"
          :to="partnerLink?.url"
          :title="partnerLink?.title"
          target="_blank"
          class="p-20 md:grayscale default-transition hover:grayscale-0 grid place-content-center relative group"
        >
          <base-button
            class="md:hidden absolute -rotate-45 top-5.2 right-5.2 text-integer-white"
            onlyIcon
            transparent
            ><Arrow
          /></base-button>
          <base-image
            v-bind="partnerLink?.image?.data?.attributes"
            class="max-w-xs"
            sizes="20rem"
          />
        </nuxt-link>
      </div>
    </div>
  </section>
</template>
